.legend:global(.ms-Button) {
    position: fixed;
    right: 40px;
    bottom: 40px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 100;
}

.legendIcon {
    font-weight: 900;
}

.legendContent {
    display: flex;
    flex-direction: column;

    &>* {
        margin: 0;
        margin: 2px 0;
        // text-align: center;
    }
}

.draft {
    border: 3px dotted black;
    border-radius: 2px;
    text-align: center;
}

.published {
    border: 3px solid black;
    border-radius: 2px;
    text-align: center;
}

.cancellationRequested {
    border: 3px double black;
    border-radius: 2px;
    text-align: center;
}

.unassigned {
    border: 3px solid #aaaaaa;
    border-radius: 2px;
    text-align: center;
}

.noIssues {
    border: 3px solid #6bb700;
    text-align: center;
}

.softAlert {
    border: 3px solid #ffaa44;
    text-align: center;
}

.hardAlert {
    border: 3px solid #c50f1f;
    text-align: center;
}
.eventTypeColor{
    height: 10px;
    width: 10px;
}

.cancelrequestcontainer {  
    text-align: center;
    border-style: solid;
    background-color: rgb( 252,251, 237);
    border-color: #6bb700;   
  }