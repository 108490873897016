.resourceItem {
  position: relative;
  margin-left: 5px;
  height: 100px;
  display: flex;
  align-items: center;


  .iconContainer {
    padding-right: 5px;

    .imageBox {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      overflow: hidden;

      .initials {
        height: 100%;
        width: 100%;
        background: #f37037;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
      }
    }
  }

  .resource-info {
    height: 100px;
    width: 200px;
    background: red;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.colorSpan {
  //height: 116%;
  //width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.warning-message {
  background-color: #fff3cd;
  color: #856404;
}

.popup {
  border-radius: 2px;
  box-shadow: 3px 5px 7px -1px rgba(0, 0, 0, 0.1);
  padding: 1em;
}

.callout {
  padding: 10px;
  max-width: 350px;
}

.resourceView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  max-height: 350px;
  max-width: 300px;
  min-height: 150px;
  width: 300px;
  padding: 1rem;
  background: white;
  position: absolute;
  z-index: 999;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  //justify-content: space-between;
}

.divider {
  border-top: 1px solid #c1c1c1;
  width: 100%;
}


.resourceView>.charList {
  list-style: none;
}

.resource-colour-box-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;
  justify-content: center;
}

.resource-colour-box {
  height: 15px;
  width: 15px;
  display: block;
  margin-right: 5px;
}