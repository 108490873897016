// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .rb-header {
        .rb-small-button {
            min-width: 45px;
        }

        .calendar-text {
            font-size: 18px;
        }

        .ms-Button--primary,
        .ms-Button {
            padding: 0 5px;
        }

    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .rb-header {
        .rb-small-button {
            min-width: 80px;
        }

        .ms-Button--primary,
        .ms-Button {
            padding: 0 16px;
        }

        .calendar-text {
            font-size: 28px;
        }
    }
}