#calender {
  //background: linear-gradient(0deg, rgba(242,242,242,1) 8%, rgba(253,29,29,0) 8%, rgba(252,176,69,0) 100%);
}

.fc .fc-button {
  outline: transparent;
  position: relative;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(138, 136, 134);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background: transparent;
  color: rgb(32, 31, 30);

  &:hover {
    background: rgb(243, 242, 241);
    color: rgb(32, 31, 30);
  }

  &.fc-today-button {
    background: rgb(16, 110, 190);
    color: white;
    text-transform: capitalize;
  }

  &.fc-button-active :active {
    background: rgb(237, 235, 233) !important;
  }
}

.fc-event-selected, .fc-event:focus, .fc-event-dragging {
  box-shadow: none;

  &:after, &:before {
    content: none;
  }

  .eventContainer:after {
    content: '';
  }
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: rgb(237, 235, 233) !important;
  color: black;
}

.fc-event {
  background: transparent;
  padding: 0 !important;
  //margin: 0 !important;
  //width: 98%;
  margin: 0 4px !important;
}
.fc-timeline-event:not(.fc-event-end):after {
  position: absolute;
  right: 2px;
}
.fc-timeline-event:not(.fc-event-start):before {
  position: absolute;
  left: 2px;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--fc-border-color, #ddd);
}

.fc-h-event {
  border: none !important;
}

.ms-Modal-scrollableContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fc-scroller {
}
.fc-scroller,
.fc-scroller.fc-scroller-liquid-absolute {
  overflow-x: scroll !important;
  overflow-y: auto !important;
}

.fc-scrollgrid-section-header {
  .fc-scroller {
    overflow-x: auto !important;
  }
}

.fc-datagrid-header {
  background: white;
}

.button-group {
  //&.button-group > * {
  //  margin-right: 2px;
  //}
  //margin-right: 2px;

  button.ms-Button--primary {
    background: transparent;
    color: black;
    &.is-checked {
      background: rgb(243, 112, 55);
      color: white;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
}

ul {
  padding: 0 5px;
  margin: 0;
  margin-left: 10px;
  white-space: break-spaces;
}

.fc .fc-datagrid-header .fc-datagrid-cell-frame {
  width: 100%;
  .fc-scrollgrid-sync-inner {
    width: 100% !important;
    .fc-datagrid-cell-main {
      width: 100%;
    }
  }
}

.fc-scrollgrid-sync-table {
  tbody {
    tr {
      background: white;
      &:last-child {
        .fc-day {
          background: white;
        }
        td {
          padding: 1rem 0;
          border: none;
        }
        padding: 1rem;
        background: #f2f2f2;
        //border-top: 4px solid;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.loading-wheel {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 30px;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
}
.loading-text {
  top: 42%;
  left: 49%;
  position: absolute;
  color: #fff;
}
.style-2 .loading-wheel {
  border-style: double;
  border-color: #ccc transparent;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
.swal2-container{
  z-index: 1000001 !important;
}
