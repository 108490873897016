.eventContainer {
  padding: 10px 0;
  margin: 2px 0;
  min-height: 2.5rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;

  p {
    margin: 0;
    text-align: center;
  }

  &:hover {
    background-color: #fafafa;
    box-shadow: -1px 7px 7px -3px rgba(0, 0, 0, 0.1);
  }

  &.success {
    border-color: var(--color-successIcon);

    &:after {
      background-color: var(--color-successBackground);
    }

    .color-inherit {
      color: var(--color-successIcon);
    }
  }

  &.warning {
    border-color: var(--color-warningIcon);

    &:after {
      background-color: var(--color-warningBackground);
    }

    .color-inherit {
      color: var(--color-warningIcon);
    }
  }

  &.error {
    border-color: var(--color-errorIcon);

    &:after {
      background-color: var(--color-errorBackground);
    }

    .color-inherit {
      color: var(--color-errorIcon);
    }
  }

  &.info {
    border-color: var(--color-infoIcon);

    &:after {
      background-color: var(--color-infoBackground);
    }

    .color-inherit {
      color: var(--color-infoIcon);
    }
  }

  &.border-solid {
    border-style: solid;
  }

  &.border-solid-cancel-request {
    border-style: solid;
    background-color: rgb(252, 251, 237);
  }

  &.unavailable {
    border: 1px solid red;
    background-color: #ffe5e5;
    color: black;
    opacity: 0.3;
  }

  &.preferred {
    border: 1px solid green;
    background-color: #cce5cc;
    color: black;
    opacity: 0.3;
  }

  &.border-dotted {
    border-style: dotted;
  }

  &.border-double {
    border-left-style: double;
    border-top-style: double;
  }

  &::after {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border-radius: 2px;
  }

  &.loading {
    background: linear-gradient(90deg, #e5e5e5 30%, #f2f2f2, #e5e5e5 70%) right/300% 100%;
    animation: shimmer 2.5s infinite;
    mask-mode: luminance;
  }
}

@keyframes shimmer {
  100% {
    background-position: left
  }
}

//.eventIndicator {
//  //background-color: rgb(243, 112, 55);
//  //border: 2px solid rgb(243, 112, 55);
//  position: absolute;
//  width: 100%;
//
//&:after {
//  border: 2px solid rgb(243, 112, 55);
//
//  content: "" ;
//  //background: #00A38D;
//  display: block;
//  height: 10px;
//  width: 100%;
//  position: absolute;
//  bottom: -14px;
//  left: 0;
//}
//
//}

.bellContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin: 1px;
}

.warning-icons-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  margin: 1px;
}

.ms-Grid {
  //padding: 1rem 0;
}

.ms-Grid-row {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #7c7c7c !important;
}

.gap {
  padding: 0.5rem;
}

.event-icon-container {
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 0;

  .event-icon {
    border-radius: 8px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    gap: 3px;

    &.icon-group {
      background-color: #fdeae1;
    }
  }
}

.bidder-event-icon-container {
  z-index: 3;
  display: flex;
  justify-content: center;
  position: relative;

  .bidder-event-icon {
    border-radius: 8px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    gap: 3px;

    &.icon-group {
      background-color: #fdeae1;
    }
  }
}


.eventModal {
  width: 60vw;
  height: 60vh;
  padding: 1.5rem;
  border-radius: 4px;

  a {
    color: black;
    text-decoration: underline;
  }

  .modal-header {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-modal-container {
    display: flex;
    gap: 1em;
    justify-content: flex-end;
  }

  .cancel-request-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;

    .button-cancel-request-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .publish-container {
    border: 1px solid tomato;
  }

  .modal-content {
    display: flex;
    flex: 1;

    .info {
      box-sizing: border-box;
      padding: 1rem 1rem 1rem 0;
      height: 100%;
      flex: 3;
      border-right: 1px solid;

      h2 {
        display: inline-flex;
        align-items: center;
      }
    }

    .options {
      flex: 1;
      padding: 1rem;

      .text {
        margin-bottom: 0.5rem;
      }
    }
  }

  .shift-cancel-button {
    width: 100%;
    margin-top: 10px;
  }
}

.time-input.ms-ComboBox-container {
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  color: rgb(36, 35, 35);
  font-weight: bold;
  min-width: 0;
  font-size: 24px;
  outline: none;
  width: fit-content;
  border: none;

  .ms-ComboBox {
    padding-left: 0;

    &:not(:hover, .is-open) {
      padding-right: 0;

      .ms-Button-flexContainer {
        display: none;
      }

      &:after {
        border: none;
      }
    }
  }

  input {
    width: fit-content;
    max-width: 8ch;
    font-size: 24px;
    font-weight: bold;
  }
}

.eventModal.bidder-errors {
  width: 50vw;
}

.details-box {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin: 10px 0;

  .ms-Label {
    padding-bottom: 2px;
  }

  summary,
  .ms-Label {
    user-select: none;
  }
}

.table {
  //border: 2px solid red;
  width: 100%;
  background-color: #f2f2f2;

  th {
    border-right: 2px solid #ffffff;
  }

  th:last-child {
    border-right: none;
  }
}

.notes {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--fc-border-color, #ddd);
  border-bottom: 1px solid var(--fc-border-color, #ddd);
  border-right: 1px solid var(--fc-border-color, #ddd);

  .notes-header {
    height: 48px;
    background: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}

.notes-content {
  border-top: 1px solid var(--fc-border-color, #ddd);

  &.full-height {
    flex: 1;
  }
}

.external-link-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 1rem;
  width: 1rem;
  padding: 0;

  .ms-Icon {
    color: #808080;
    font-size: 0.625rem;
  }
}

.event-type-color {
  height: 10px;
  width: 10px;
  left: 0;
  bottom: 0;
  position: absolute;
}